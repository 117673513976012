import React, {useState} from 'react'
import styles from './operations.module.css';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms';
import {RiExchangeDollarLine} from 'react-icons/ri';
import {BiReceipt} from 'react-icons/bi';
import Expenses from './expenses';
import ExchangeRate from './exchangeRate';
import Refills from '../refills';
import {BlockTitle} from '../../components/blocks';
import Promotickets from "../promotickets";


const OperationOthers = () => {

  const [activeTab, setActiveTab] = useState('gastos');

  return (
    <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className='flex flex-col flex-1'>
      <TabsContent value="gastos">
        <BlockTitle title='OTROS GASTOS' className='mt-0 mb-3'/>
      </TabsContent>
      <TabsContent value="tipoCambio">
        <BlockTitle title='TIPO DE CAMBIO' className='mt-0 mb-3'/>
      </TabsContent>
      <TabsContent value="refill">
        <BlockTitle title='RELLENOS' className='mt-0 mb-3'/>
      </TabsContent>
      <TabsContent value="promo">
        <BlockTitle title='PROMOCIONALES' className='mt-0 mb-3'/>
      </TabsContent>
      <div className='flex flex-col self-stretch flex-1 p-4 bg-neutral-800 rounded-2xl'>
        <TabsList>
          <TabsTrigger value="gastos" asChild>
            <div className={styles.customTabsTrigger}>
              <BiReceipt size={24} />
              <span>Gastos</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="tipoCambio" asChild>
            <div className={styles.customTabsTrigger}>
              <RiExchangeDollarLine size={24} />
              <span>Tipo cambio</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="refill" asChild>
            <div className={styles.customTabsTrigger}>
              <RiExchangeDollarLine size={24} />
              <span>Rellenos</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="promo" asChild>
            <div className={styles.customTabsTrigger}>
              <RiExchangeDollarLine size={24} />
              <span>Promocionales</span>
            </div>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="gastos">
          <Expenses />
        </TabsContent>
        <TabsContent value="tipoCambio">
          <ExchangeRate />
        </TabsContent>
        <TabsContent value="refill">
          <Refills />
        </TabsContent>
        <TabsContent value="promo">
          <Promotickets />
        </TabsContent>
        </div>
    </Tabs>
  )
}

export default OperationOthers
