import React, {useContext, useMemo} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {format, parseISO} from 'date-fns';
import {Button} from '../../components/atoms';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {AsyncContent, EmptyState, ReactTable} from '../../components/blocks';
import {formatNumber} from '../../utils/general';
import CardSummary from "./hisUtils";

const HisExchangeRate = () => {

  const {fondo, config} = useContext(SiteContext);

  const {data, isFetched, isLoading, isError, error} = useQuery({
    queryKey: ['getExchangeRates', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-exchange-rate?fondoId=' + fondo?.id)
      .then((res) => res.data)
      .catch((err) => {
        console.error('Error fetching exchange rates history', err);
        throw err;
      }),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/operation-exchange-rate/' + id);

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_exchangeRate', //mutateKey
    'getExchangeRates', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'De',
        id: 'enteredAmount',
        accessorFn: row => `${(row.enteredAmount ? formatNumber(row.enteredAmount, '$') : '--')}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Cambio',
        id: 'rate',
        accessorFn: row => `${row.rate !== '' ? row.rate.toFixed(2) : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'A',
        id: 'amountDelivered',
        accessorFn: row => `${(row.amountDelivered ? formatNumber(row.amountDelivered, config.symbol) : '--')}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm'
                    onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm'>Imprimir</Button>
          </div>
        ),
      }
    ],
    [deleteModal, config]
  );

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <AsyncContent
        data={data}
        isFetched={isFetched}
        isLoading={isLoading}
        isError={isError}
        error={error}
      >
        <div className='flex flex-row items-center gap-4'>
          <CardSummary
            text='Nro de Operaciones'
            value={data?.quantity}
          />
          <CardSummary
            text='Total'
            value={formatNumber(data?.totalAmount, config.symbol)}
          />
        </div>
        {data && data.data.length > 0 ?
          <ReactTable columns={columns} data={data.data} />
          :
          <div className="relative w-full h-full overflow-hidden">
            <EmptyState />
          </div>
        }
      </AsyncContent>
      <deleteModal.ConfirmationModal/>
    </div>
  )
}

export default HisExchangeRate
