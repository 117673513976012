import React, {useContext} from 'react';
import {Button, Dialog, DialogContent, DialogHeader, DialogOverlay, DialogTitle} from "../../atoms";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {Apis} from "../../../utils/apis";
import {SiteContext} from "../../../context/SiteContext";
import {toast} from "../../../hooks/useToast";
import {HiMiniBellAlert} from "react-icons/hi2";

const DialogPrebill = ({open, setOpen, data}) => {

  const {fondo, printer, config} = useContext(SiteContext);

  const billMutation = useMutation({
    mutationKey: ['op_bill'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/operation-billetero', data),
    onSuccess: () => {
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      setOpen(false);
      stackerMutation.mutate({
        id: data.id
      });
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const stackerMutation = useMutation({
    mutationKey: ['op_stacker'],
    mutationFn: (data) => axios.post(Apis.TGM + '/stacker-alert/' + data.id + '/ack' , data),
    onSuccess: () => {
      console.log('stackerMutation success');
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (data && data.amount > 0) {
      billMutation.mutate({
        amount: data.amount ? data.amount : null,
        confId: data.confId ? data.confId : null,
        fondoId: fondo?.id,
        printerId: printer?.printerId
      });
    }
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay/>
        <DialogContent className='flex flex-col gap-4 p-9'>
          <DialogHeader>
            <DialogTitle>
              <div style={{display: 'flex', alignItems: 'center'}}>
                  <span style={{display: 'flex'}}>
                    <HiMiniBellAlert size={35} color={'#FF0000'}/>
                  </span>
                <span style={{marginLeft: '8px', display: 'flex', fontSize: '2rem', fontWeight: 'bold'}}>
                    Stacker removido
                  </span>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div>
            <div className='flex flex-col gap-6'>
              {
                data &&
                <div className='flex flex-col gap-4'>
                  <div className="flex flex-row">
                    <span className='flex-1'>Maquina: </span>
                    <span
                      className='text-lg font-bold text-right'>{data.machine}</span>
                  </div>
                  <div className="flex flex-row">
                    <span className='flex-1'>Modelo: </span>
                    <span
                      className='text-lg font-bold text-right'>{data.machineGroupName}</span>
                  </div>
                  <div className="flex flex-row">
                    <span className='flex-1'>Monto: </span>
                    <span
                      className='text-lg font-bold text-right'>{data.amount ? `${config.symbol} ${Number(data.amount).toFixed(2)}` : '--'}</span>
                  </div>
                </div>
              }
              <Button size='lg' onClick={handleSubmit}>Agregar a fondo</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogPrebill;
