import React from 'react';
import {FaArrowRight} from "react-icons/fa";
import {Button, Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from '../../atoms';
import {cn} from '../../../utils/general';

const SidebarHistory = ({children, title, className, open, setOpen, showTrigger = true}) => {

  return (
    <Sheet open={open} onOpenChange={setOpen} modal={false}>
      {showTrigger &&
        <SheetTrigger asChild>
          <div className='w-[106px]'>
            <Button size='sm' type='button' variant='secondary' className={cn('px-6', className)}>
              Historial
            </Button>
          </div>
        </SheetTrigger>
      }
      <SheetContent
        className="w-[60vw] flex flex-col p-4"
        onInteractOutside={(event) => {
          if (event.target.closest('.history')) {
            event.preventDefault();
          }
        }}
      >
        <div className="absolute left-4 top-4">
          <SheetClose asChild>
            <Button variant='ghost' className='w-10 h-10 p-0 rounded-xl'>
              <FaArrowRight />
            </Button>
          </SheetClose>
        </div>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <div className='overflow-y-auto'>
          {children}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SidebarHistory;
