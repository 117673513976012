import React from 'react';
import {LuAlertTriangle} from 'react-icons/lu';
import {cn} from "../../../utils/general";

const ErrorState = ({
  title = 'Ocurrió un error',
  message = 'Hubo un problema al cargar los datos. Por favor, intenta de nuevo más tarde.',
  icon = <LuAlertTriangle size={48}/>,
  className = 'text-neutral-500'
}) => {
  return (
    <div className={cn("flex flex-col items-center justify-center gap-4", className)}>
      <span className="text-lg font-bold">{title}</span>
      {icon}
      <span className="text-sm font-light">{message}</span>
    </div>
  );
};

export default ErrorState;

