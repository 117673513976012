import React, {useContext} from 'react';
import {SiteContext} from "../../../context/SiteContext";
import styles from "../online-handpays/online-handpays.module.css";
import {MdFiberManualRecord} from "react-icons/md";
import {IoFileTrayOutline} from "react-icons/io5";
import {cn} from "../../../utils/general";
import {HiOutlineSlotMachine} from "../../../assets/icons/slot-machine";

const OnlineBill = ({selectedMachine, setSelectedMachine, setAmountBill, data}) => {
  const {config} = useContext(SiteContext);

  const handleMachine = (billOnline) => {
    if (selectedMachine && selectedMachine.confId === billOnline.confId) {
      setSelectedMachine({confId: null, machineName: ''});
      setAmountBill('')
    } else {
      setSelectedMachine({id: billOnline.id, confId: billOnline.confId, machineName: billOnline.machine});
      setAmountBill(billOnline?.billOnline)
    }
  };

  return (
    <div className={styles.container}>
      <div className='relative flex flex-row items-start justify-center'>
        <span className='text-lg font-semibold text-center'>Billeteros en línea</span>
        <MdFiberManualRecord size={12}
                             className='absolute top-0 max-[1024px]:right-2 right-5 animate-ping text-success-500'/>
      </div>

      {data?.length === 0 ?
        <div className="flex flex-col items-center gap-4 mt-4 text-center text-neutral-400">
          <span className='text-sm font-light'>No hay billeteros en línea</span>
          <IoFileTrayOutline size={36}/>
        </div>
        :
        data?.sort((a, b) => b.expected - a.expected).map((row) => (
          <div
            key={row.id}
            className={cn(styles.containerItem, selectedMachine?.confId === row?.confId ? styles.selectedMachine : '')}
            onClick={() => handleMachine(row)}
          >
            <div className='relative flex flex-row gap-2'>
              <div className={cn(styles.containerIcon)}>
                <HiOutlineSlotMachine size={24}/>
              </div>
              <div className='flex flex-col'>
                <span className='font-bold'>{row.machine}</span>
                <span className='text-sm font-normal text-neutral-500'>{row.groupName || '---'}</span>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-1">
              <div>
                <span>Monto: </span>
                <span className="font-bold"> {config.symbol} {row.expected}</span>
              </div>
              {/*{row.prebillAudit > 0 && (*/}
              {/*  <div className="ml-auto flex flex-row gap-1 items-center">*/}
              {/*    <span className="text-xs font-light">Prebill: </span>*/}
              {/*    <span className="text-xs font-light"> {config.symbol} {row.prebillAudit}</span>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </div>
        ))
      }
    </div>
  )
};

export default OnlineBill;
