import React, {useState} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogOverlay, DialogTitle} from '../components/atoms';
import {toast} from './useToast';

const useConfirmDialog = (texto, mutateKey, queryKey, action) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [id, setId] = useState(null);
  const queryClient = useQueryClient();
  
  const deleteElement = useMutation({
    mutationKey: [mutateKey, id],
    mutationFn: (id) => {
      return action(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [queryKey]});
      queryClient.invalidateQueries({queryKey: ['getPreArqueo']});
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  })

  const handleDeleteConfirmation = (confirmed) => {
    if (confirmed) {
      deleteElement.mutate(id);
    }
    setShowConfirmation(false);
  };

  const handleClick = (id) => {
    setId(id);
    setShowConfirmation(true);
  };

  const ConfirmationModal = () => (
    <Dialog open={showConfirmation} onOpenChange={setShowConfirmation}>
      <DialogOverlay />
      <DialogContent
        className='flex flex-col gap-4'
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Confirmación</DialogTitle>
        </DialogHeader>
        <span className='py-4'>{texto}</span>
        <DialogFooter>
          <Button variant="outline" onClick={() => handleDeleteConfirmation(false)}>
            Cancelar
          </Button>
          <Button onClick={() => handleDeleteConfirmation(true)}>
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  return {
    handleClick,
    ConfirmationModal
  };
};

export default useConfirmDialog;
