import React, {useContext, useEffect, useState} from 'react'
import {useMutation, useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {Apis} from '../../../utils/apis'
import {SiteContext} from '../../../context/SiteContext'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../atoms'
import BlockLoading from '../block-loading'
import {LuUsers2} from 'react-icons/lu'
import {useNavigate} from 'react-router-dom'
import {cn, formatNumber} from '../../../utils/general'
import {toast} from '../../../hooks/useToast'
import DialogConfirm from '../dialog-confirm'
import {queryClient} from '../../..'


const DialogTurns = () => {

  const {fondo, setFondo, salaId, printer, config} = useContext(SiteContext);
  const [open, setOpen] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [createSuccess, setCreateSuccess] = useState(false);
  const [selectedFundTypeId, setSelectedFundTypeId] = useState(null);
  const [iniAmount, setIniAmount] = useState('');
  const [fixedFundType, setFixedFundType] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [sourceFondo, setSourceFondo] = useState([]);

  const [fundTypeIdLocalStorage, setFundTypeIdLocalStorage] = useState(true);

  const {data: allFondos = []} = useQuery({
    queryKey: ['getAllFondos'],
    queryFn: async () => await axios.get(Apis.CAJA + '/fund-type?type=' + 2)
      .then((res) => res.data)
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (fondo && printer) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [fondo, printer]);

  const {data: fondos = [], isLoading} = useQuery({
    queryKey: ['getCashierTurn'],
    queryFn: async () => {
      const config = {
        headers: {
          'X-Sala-ID': salaId
        }
      };
      const response = await axios.get(Apis.CAJA + '/fondos/by-user?active=true', config);
      return response.data;
    }
  })

  const {data: fondosOpen = []} = useQuery({
    queryKey: ['getComboFondo'],
    queryFn: async () => await axios.get(Apis.CAJA + '/fondos?active=true')
      .then((res) => res.data)
  })

  useEffect(() => {
    if (fondosOpen) {
      let option = {id: null, name: 'Empezar de cero'};
      setSourceFondo([option, ...fondosOpen]);
    }
  }, [fondosOpen])

  const bankMutation = useMutation({
    mutationKey: ['op_get_banks'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/bank', data),
    onSuccess: (response) => {
      localStorage.setItem('banks', JSON.stringify(response.data));
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const expensesCategoryMutation = useMutation({
    mutationKey: ['op_get_expenses_category'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/expenses-category', data),
    onSuccess: (response) => {
      localStorage.setItem('expenses', JSON.stringify(response.data));
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handleFondoSelection = (selectedFondo) => {
    setFondo(selectedFondo);
    localStorage.setItem('fondo', JSON.stringify(selectedFondo));
    bankMutation.mutate();
    expensesCategoryMutation.mutate();
  };

  const createMutation = useMutation({
    mutationKey: ['createTurn'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/fondos', data),
    onSuccess: () => {
      setCreateSuccess(true)
      queryClient.invalidateQueries({queryKey: ['getCashierTurn']});
      toast({
        title: "Operación realizada",
        variant: 'success',
        duration: 2000
      })
      setConfirmOpen(false)
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = () => {
    const fundTypeFinal = fixedFundType ? fundTypeIdLocalStorage.id : selectedFundTypeId;

    const fundTypeSelectToSave = allFondos.find(fondo => fondo.id === fundTypeFinal);
    localStorage.setItem('fundType', JSON.stringify(fundTypeSelectToSave));

    createMutation.mutate({
      iniAmount: iniAmount,
      fundTypeId: fundTypeFinal,
      sourceFondoId: selectedOption?.id || null,
    });
  };

  useEffect(() => {
    if (selectedFundTypeId > 0) {
      console.log('fund type id selected', selectedFundTypeId);
      const fundType = allFondos.find(fondo => fondo.id === selectedFundTypeId);
      localStorage.setItem('fundType', JSON.stringify(fundType));
    }
  }, [selectedFundTypeId, allFondos])

  useEffect(() => {
    const fundType = localStorage.getItem('fundType');
    if (fundType !== undefined && fundType !== null && fundType !== "undefined") {
        console.log('fund type id found in local storage', JSON.parse(fundType));
        setFundTypeIdLocalStorage(JSON.parse(fundType));
        setFixedFundType(true);
    }
  }, [])

  const handleConfirm = () => {
    handleSubmit();
  };

  const selected = allFondos.find(fondo => fondo.id === selectedFundTypeId);

  return (
    <>
      <Dialog open={open} onOpenChange={(isOpen) => {
        if (fondo && printer) {
          setOpen(isOpen);
        } else {
          setOpen(true);
        }
      }}>
        <DialogOverlay/>
        <DialogContent
          className='flex flex-col gap-4'
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>{config?.onlyCashier ? 'Tus fondos asignados' : 'Fondos'}</DialogTitle>
            {fondos.length > 0 &&
              <DialogDescription>
                Selecciona un fondo e impresora para poder continuar:
              </DialogDescription>
            }
          </DialogHeader>
          {isLoading  ? <BlockLoading/> : (
            fondos.length === 0 ?
              <div>
                {config?.onlyCashier && !createSuccess ?
                  <div className="flex flex-col gap-6 px-2 mt-4">
                    <FormControl>
                      <label>Posicion</label>
                      {
                        fixedFundType ?
                          <label>
                            <span>{fundTypeIdLocalStorage.name}</span>
                            <span style={{paddingLeft : '8px', color: 'gray', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setFixedFundType(false)}>cambiar</span>
                          </label>
                          :
                          <Select onValueChange={setSelectedFundTypeId} value={selectedFundTypeId}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Seleccione posicion"/>
                            </SelectTrigger>
                            <SelectContent>
                              {allFondos.map((fondo) => (
                                <SelectItem key={fondo.id} value={fondo.id}>
                                  {fondo.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                      }

                    </FormControl>
                    <FormControl>
                      <label>Fondo origen</label>
                      <div className='flex flex-col gap-6'>
                        <div className={cn('grid gap-4', fondosOpen.length === 1 ? 'grid-cols-1' : 'grid-cols-2')}>
                          {sourceFondo.map((option) => (
                            <div
                              key={option.id}
                              className={cn(
                                'flex flex-col items-center justify-center gap-1 p-4 border cursor-pointer rounded-2xl hover:bg-neutral-900',
                                selectedOption === option ? 'bg-white text-neutral-950 border-neutral-300  hover:bg-neutral-100' : 'border-neutral-500'
                              )}
                              onClick={() => setSelectedOption(option)}
                            >
                              {option.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </FormControl>
                    <FormControl>
                    <label>Monto Inicial</label>
                      <Input
                        type="number"
                        value={iniAmount}
                        onChange={(e) => setIniAmount(e.target.value)}
                        placeholder="Monto"
                        className='h-10 text-base placeholder:text-base'
                      />
                    </FormControl>
                    <div className='flex flex-row items-center justify-end gap-2'>
                      <Button variant='outline' onClick={() => navigate('/selector')}>Cancelar</Button>
                      <Button onClick={() => setConfirmOpen(true)}>Aperturar</Button>
                    </div>
                  </div>
                  :
                  <div className="flex flex-col items-center gap-6 mt-4 text-center">
                    <span>Por el momento no hay fondos disponibles. Por favor, contacta con el supervisor.</span>
                    <LuUsers2 size={36}/>
                    <Button onClick={() => navigate('/selector')}>Aceptar</Button>
                  </div>
                }
              </div>
              :
              <div className='flex flex-col gap-6'>
                <div className={cn('grid gap-4', fondos.length === 1 ? 'grid-cols-1' : 'grid-cols-2')}>
                  {fondos.map((turn) => (
                    <div
                      key={turn.id}
                      className={cn(
                        'flex flex-col items-center justify-center gap-1 p-4 border cursor-pointer rounded-2xl hover:bg-neutral-900',
                        fondo?.id === turn.id ? 'bg-white text-neutral-950 border-neutral-300  hover:bg-neutral-100' : 'border-neutral-500'
                      )} onClick={() => handleFondoSelection(turn)}
                    >
                      <span>{turn?.name}</span>
                      <span className='text-sm text-neutral-500'>Periodo: {turn?.period}</span>
                    </div>
                  ))}
                </div>
              </div>
          )}
        </DialogContent>
      </Dialog>
      <DialogConfirm
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
        disabled={createMutation.isPending}
        body={
          <div className='flex flex-col gap-4 my-4'>
            <span className='mb-4 text-neutral-500'>¿Los datos ingresados son correctos?</span>
            <div className='flex flex-row gap-2'>
              <span>Fondo seleccionado:</span>
              <span className='font-bold'>{selected?.name}</span>
            </div>
            <div className='flex flex-row items-end gap-3'>
              <span>Monto inicial:</span>
              <span className='text-xl font-bold'>{formatNumber(iniAmount, config.symbol)}</span>
            </div>
          </div>
        }
      />
    </>
  )
}

export default DialogTurns
