import React from 'react'
import {cn} from '../../../utils/general'

const BlockTitle = ({title, className}) => {
  return (
    <div className={cn('flex flex-row items-center justify-center gap-4 my-2',className)}>
      <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
      <span className='font-light uppercase text-neutral-500'>{title}</span>
      <div className='h-[1px] bg-neutral-500 flex-1 flex'/>
    </div>
  )
}

export default BlockTitle