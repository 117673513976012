import React, {useContext, useState} from 'react'
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, Input} from '../../atoms';
import {SiteContext} from '../../../context/SiteContext';

const DialogMoney = ({open, setOpen,setAmount}) => {

  const {config} = useContext(SiteContext);
  const [bill200, setBill200] = useState('');
  const [bill100, setBill100] = useState('');
  const [bill50, setBill50] = useState('');
  const [bill20, setBill20] = useState('');
  const [bill10, setBill10] = useState('');
  const [coins, setCoins] = useState('');
  const [vales, setVales] = useState('');

  const parseValue = (value) => value === '' ? 0 : parseFloat(value);

  const getTotal = () => {
    return parseValue(bill200) * 200 + parseValue(bill100) * 100 + parseValue(bill50) * 50 + parseValue(bill20) * 20 + parseValue(bill10) * 10 + parseValue(coins) + parseValue(vales);
  }

  const total = getTotal()

  const calculateTotal = () => {
    const totalAmount = getTotal();
    setAmount(totalAmount);
    
    setOpen(false);
  };

  const handleInputChange = (setValue) => (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateTotal();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogOverlay />
      <DialogContent className='flex flex-col gap-4'>
        <DialogHeader>
          <DialogTitle>Detalle</DialogTitle>
          <DialogDescription>
            Por favor, ingrese el detalle del monto:
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className='flex flex-col gap-4 px-4'>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>200.00</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoComplete="off"
              value={bill200}
              onChange={handleInputChange(setBill200)}
            />
          </div>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>100.00</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoComplete="off"
              value={bill100}
              onChange={handleInputChange(setBill100)}
            />
          </div>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>50.00</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoComplete="off"
              value={bill50}
              onChange={handleInputChange(setBill50)}
            />
          </div>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>20.00</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoComplete="off"
              value={bill20}
              onChange={handleInputChange(setBill20)}
            />
          </div>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>10.00</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoComplete="off"
              value={bill10}
              onChange={handleInputChange(setBill10)}
            />
          </div>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>Monedas</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoFocus
              autoComplete="off"
              value={coins}
              onChange={handleInputChange(setCoins)}
            />
          </div>
          <div className='flex flex-row items-center'>
            <label className='flex-1 text-lg font-bold'>Vales</label>
            <Input
              placeholder="0"
              className='flex-1 text-right'
              type="number"
              autoComplete="off"
              value={vales}
              onChange={handleInputChange(setVales)}
            />
          </div>
          <div className='flex flex-row items-center pt-4 border-t border-dashed border-neutral-500'>
            <span className='flex-1 text-lg font-bold'>Total Soles:</span>
            <span className='flex-1 pr-4 text-xl font-bold text-right'> {config.symbol} {total}</span>
          </div>
          <div className='flex flex-row self-stretch flex-1 gap-4 mt-4'>
            <Button variant='outline' type='button' className='flex-1' onClick={() => setOpen(false)}>Cancelar</Button>
            <Button className='flex-1' type="submit">Confirmar</Button>
          </div>
        </form>

      </DialogContent>
    </Dialog>
  )
}

export default DialogMoney
