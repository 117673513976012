import React, {useContext} from 'react'
import {cn} from '../../../utils/general';
import axios from 'axios';
import {SiteContext} from '../../../context/SiteContext';
import {useQuery} from '@tanstack/react-query';

const TicketDispenser = () => {

  const {printer} = useContext(SiteContext);
  const computerId = printer?.computerId;

  const fetchStatus = async () => {
    try {
      const response = await axios.get('https://servidor.wargos.cloud/xticket/status', {
        params: {computerId}
      });
      return response.data;
    } catch (error) {
      console.error('Servidor no responde', error);
    }
  };

  const {data, error} = useQuery({
    queryKey: ['fetchTicketDispenserStatus', computerId],
    queryFn: fetchStatus,
    enabled: !!computerId,
    refetchInterval: 3000,
  });

  const getStatusText = () => {
    if (error || !data) {
      return 'Servidor no responde';
    }
    if (data.error) {
      return `${data.errorMessage}`;
    }
    if (data.online) {
      return `${data.printerType || 'Dispensador'} - Conectado`;
    }
    return 'Aplicación cerrada';
  };

  const getStatusColor = () => {
    if (error || !data) {
      return 'bg-neutral-500';
    }
    if (data.error) {
      return 'bg-red-600';
    }
    if (data.online) {
      return 'bg-success-600';
    }
    return 'bg-amber-500';
  };

  return (
    <div className='flex flex-row items-center gap-2'>
      <div className={cn("flex items-center justify-center rounded-full px-3 py-1 transition-all ease-in-out", getStatusColor())}>
        <span>{getStatusText()}</span>
      </div>
    </div>
  )
}

export default TicketDispenser
