import React, {useContext, useEffect, useState} from 'react'
import {SiteContext} from '../../context/SiteContext';
import styles from './operations.module.css';
import {Button, FormControl, Input} from '../../components/atoms';
import {BlockTitle, ComboClient, DialogAddCard, SidebarHistory} from '../../components/blocks';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Apis} from '../../utils/apis';
import axios from 'axios';
import {toast} from '../../hooks/useToast';
import HisCardpay from "../historial/hisCardpay";
import amexLogo from '../../assets/images/amex.png'
import visaLogo from '../../assets/images/visa.png'
import mastercardLogo from '../../assets/images/mastercard.png'
import dinersLogo from '../../assets/images/diners.png'
import chip from '../../assets/images/chip.png'
import backgroundCard from '../../assets/images/bg.png'
import {LuNfc, LuPlusCircle, LuTrash2} from 'react-icons/lu';
import {cn} from '../../utils/general';
import useConfirmDialog from '../../hooks/useConfirmDialog';

const CardPay = () => {

  const {fondo, config} = useContext(SiteContext);
  const [amount, setAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [openCard, setOpenCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const {data,refetch} = useQuery({
    queryKey: ['getUserCards', selectedCustomer?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/card-history?customerId=' + selectedCustomer?.id)
      .then((res) => res.data),
    enabled: !!selectedCustomer?.id
  })

  const handPayMutation = useMutation({
    mutationKey: ['op_handpay_redeem'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/operation-cardpay', data),
    onSuccess: () => {
      setSelectedCustomer(null)
      setAmount('')
      setSelectedCard(null)
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  useEffect(() => {
    if (data?.length === 1) {
      setSelectedCard(data[0]);
    } else {
      setSelectedCard(null);
    }
  }, [data]);

  const handleCardAdded = (newCard) => {
    refetch();
    setSelectedCard(newCard);
  };

  const handlePayClick = (event) => {
    event.preventDefault();
    if (config.genericModeCp && (selectedCard === null || selectedCard === undefined)) {
      toast({
        title: "Seleccione una tarjeta",
        variant: 'danger',
        duration: 2000
      })
      return
    }

    if (amount && (config.genericModeCp ? selectedCard : true)) {
      handPayMutation.mutate({
        amount: amount,
        cardNumber4: selectedCard?.cardNumber4,
        cardType: config.genericModeCp ? selectedCard?.cardType : 'VISA',
        customerId: config.genericModeCp ? selectedCustomer.id : null,
        bankId: config.genericModeCp ? selectedCard?.bankId : null,
        fondoId: fondo.id,
      });
    }
  };

  const getCardLogo = (type) => {
    switch (type) {
      case 'YAPE':
        return visaLogo;
      case 'VISA':
        return visaLogo;
      case 'MASTERCARD':
        return mastercardLogo;
      case 'DINERS':
        return dinersLogo;
      case 'AMEX':
        return amexLogo;
      default:
        return visaLogo;
    }
  };

  const dropElement = async (id) => {
    try {
      await axios.delete(Apis.CAJA + '/card-history/' + id);
      if (selectedCard?.id === id) {
        setSelectedCard(null);
      }
    } catch (error) {
      let errorMessage = 'Error al eliminar la tarjeta';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      console.log(errorMessage)
    }
  };

  const dropModal = useConfirmDialog(
    '¿Deseas eliminar esta tarjeta?',
    'op_drop_card', //mutateKey
    'getUserCards', //volver a llamar para refrescar la data
    dropElement 
  );

  return (
    <>
      <BlockTitle title='RETIRO POR TARJETA' />

      <SidebarHistory
        title='Historial - Pagos con tarjeta'
        className='mb-2'
      >
        <HisCardpay />
      </SidebarHistory>

      <form className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl' onSubmit={handlePayClick}>
        {config.genericModeCp &&
          <>
            <ComboClient setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer} />
            {selectedCustomer !== null &&
              <FormControl>
                <label>Seleccione tarjeta</label>
                <div className='grid items-center grid-cols-4 gap-4'>
                  {data?.map((tarjeta) => (
                    <div
                      key={tarjeta.id}
                      style={{backgroundImage: `url(${backgroundCard})`}}
                      className={cn(
                        'group',
                        styles.cardPayContainer,
                        selectedCard?.id === tarjeta.id && styles.cardSelected
                      )}
                      onClick={() => setSelectedCard(tarjeta)}
                    >
                      <div className='relative flex flex-row items-center justify-between gap-1'>
                        <span className={styles.cardBankTitle}>{tarjeta.bankId === 1 ? 'BCP': tarjeta.bankName}</span>
                          <div 
                            className={cn(styles.containerIcon,'absolute right-0 !w-8 !h-8 rounded-lg')}
                            onClick={(e) => {
                              e.stopPropagation();
                              dropModal.handleClick(tarjeta.id);
                            }}
                          >
                            <LuTrash2 size={18}/>
                          </div>
                      </div>
                      <div className='flex flex-row items-center gap-1 my-2'>
                        <img alt='chip' src={chip} height='auto' width={24} />
                        <LuNfc size={16} />
                      </div>
                      <span className={styles.cardNumber}>**** **** **** {tarjeta.cardNumber4}</span>
                      <div className='flex flex-row items-center justify-between gap-4'>
                        <span className={styles.cardTypeTitle}>{tarjeta.cardType.toUpperCase()}</span>
                        <img
                          alt='creditcard'
                          src={getCardLogo(tarjeta.cardType)}
                          height='auto'
                          width={48}
                          className='transition-transform group-hover:animate-rotateVertCenter'
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    style={{backgroundImage: `url(${backgroundCard})`}}
                    className={cn('group', styles.cardPayContainer)}
                    onClick={() => setOpenCard(true)}
                  >
                    <div className='flex flex-col items-center justify-center flex-1 h-full gap-2'>
                      <span>Agregar tarjeta</span>
                      <LuPlusCircle size={42} />
                    </div>
                  </div>
                </div>
              </FormControl>
            }
          </>
        }
        <FormControl className='flex-1'>
          <label>Monto</label>
          <Input
            placeholder="00.0"
            type='number'
            className='text-2xl font-semibold'
            autoComplete="off"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </FormControl>
        <Button size='lg' type='submit'>Guardar</Button>
      </form>
      <DialogAddCard
          open={openCard}
          setOpen={setOpenCard}
          selectedCustomer={selectedCustomer}
          onCardAdded={handleCardAdded}
        />
      <dropModal.ConfirmationModal />
    </>
  )
}

export default CardPay
