import React, {useCallback, useEffect, useState} from 'react'
import {Tabs, TabsContent, TabsList, TabsTrigger} from '../../components/atoms'
import styles from './operations.module.css';
import {RiHandCoinLine} from 'react-icons/ri'
import {BiMoneyWithdraw} from 'react-icons/bi'
import OperationOthers from './operationOthers';
import CardPay from "./cardPay";
import HandPay from './handPay';
import RedeemTickets from './redeemTickets';
import SaleTicket from './saleTickets';
import {HiOutlineTicket} from 'react-icons/hi';
import {BsCreditCard2Front} from 'react-icons/bs';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {BlockTitle} from '../../components/blocks';
import SaleTicketMostPrinted from "./saleTicketMostPrinted";

const Operations = () => {

  const [activeTab, setActiveTab] = useState('canje');
  const [focusInputFn, setFocusInputFn] = useState(null);
  const [handpayCount, setHandpayCount] = useState(0);

  const handleSetFocusInput = useCallback((focusFunction) => {
    setFocusInputFn(() => focusFunction);
  }, []);

  useEffect(() => {
    if (focusInputFn) {
      focusInputFn();
    }
  }, [focusInputFn, activeTab]);

  const {data: handpays, isSuccess} = useQuery({
    queryKey: ['getCurrentHandpays'],
    queryFn: async () => {
      const response = await axios.get(Apis.CAJA + '/operation-handpay/pending');
      return response.data;
    },
    gcTime: 1,
    refetchInterval: 5000
  });

  useEffect(() => {
    if (isSuccess && handpays) {
      setHandpayCount(handpays.length);
    }
  }, [handpays, isSuccess]);

  return (
    <div className='flex flex-row self-stretch flex-1 gap-4'>

      <div className='flex flex-col self-stretch flex-1 gap-4'>
        <div>
          <SaleTicketMostPrinted></SaleTicketMostPrinted>
        </div>

        <BlockTitle title='OPERACIONES' className='m-0'/>
        <Tabs defaultValue={activeTab} value={activeTab} onValueChange={setActiveTab} className='flex flex-col flex-1'>
          <TabsList>
            <TabsTrigger value="canje">
              <div className={styles.tabsTrigger}>
                <HiOutlineTicket size={24}/>
                <span>Canje ticket</span>
              </div>
            </TabsTrigger>
            <TabsTrigger value="venta">
              <div className={styles.tabsTrigger}>
                <HiOutlineTicket size={24}/>
                <span>Venta Ticket</span>
              </div>
            </TabsTrigger>
            <TabsTrigger value="handpay">
              <div className={styles.tabsTrigger} style={{position: 'relative'}}>
                <RiHandCoinLine size={24}/>
                {handpayCount > 0 &&
                  <div className={styles.handpayAlert}>
                    {handpayCount}
                  </div>
                }
                <span>Pago manual</span>
              </div>
            </TabsTrigger>
            <TabsTrigger value="card">
              <div className={styles.tabsTrigger}>
                <BsCreditCard2Front size={24}/>
                <span>Tarjetas</span>
              </div>
            </TabsTrigger>
            <TabsTrigger value="otros">
              <div className={styles.tabsTrigger}>
                <BiMoneyWithdraw size={24}/>
                <span>Otros</span>
              </div>

            </TabsTrigger>
          </TabsList>
          <TabsContent value="canje">
            <RedeemTickets onFocusInput={handleSetFocusInput}/>
          </TabsContent>
          <TabsContent value="venta">
            <SaleTicket onFocusInput={handleSetFocusInput}/>
          </TabsContent>
          <TabsContent value="handpay">
            <HandPay onlineHandpays={handpays}/>
          </TabsContent>
          <TabsContent value="card">
            <CardPay/>
          </TabsContent>
          <TabsContent value="otros">
            <OperationOthers/>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}

export default Operations
