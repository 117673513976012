import React, {useState} from 'react'
import {Apis} from '../../../utils/apis';
import axios from 'axios';
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, FormControl, Input} from '../../atoms';
import {useQuery} from '@tanstack/react-query'
import {LuChevronDown} from 'react-icons/lu';
import BlockLoading from '../block-loading';

const ComboMachine = ({selectedMachine, setSelectedMachine}) => {

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const {data: machines = [],isLoading} = useQuery({
    queryKey: ['getComboMachine'],
    queryFn: async () => await axios.get(Apis.TGM + '/machines/combo')
      .then((res) => res.data)
  })


  const handleInputChange = (e) => {
    setQuery(e.target.value.toLowerCase());
  };

  const filteredMachines = machines.filter(machine =>
    machine.machine.toLowerCase().includes(query)
  );

  const handleMachineSelect = (machine) => {
    setSelectedMachine({confId: machine.confId, machineName: machine.machine});
    setOpen(false);
  };

  return (
    <>
      <FormControl>
        <Button
          variant="outline"
          className='justify-between w-full'
          size='lg'
          type='button'
          onClick={() => setOpen(true)}
        >
          {selectedMachine ?
            <span>{selectedMachine.machineName}</span>
            :
            <span className='text-lg text-neutral-500'>Buscar...</span>
          }
          <LuChevronDown className="w-5 h-5 opacity-50 shrink-0" />
        </Button>
      </FormControl>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay />
        <DialogContent className='flex flex-col gap-4'>
          <DialogHeader>
            <DialogTitle>Máquina</DialogTitle>
            <DialogDescription>
              Ingrese el codigo de la máquina
            </DialogDescription>
          </DialogHeader>
          <Input
            type="text"
            onChange={handleInputChange}
            placeholder="Buscar máquina..."
          />

          <div className='flex flex-col max-h-[300px] overflow-y-auto overflow-x-hidden'>
            {isLoading && <BlockLoading />}
            {filteredMachines.map((machine) => (
              <div
                key={machine.confId}
                className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                onClick={() => handleMachineSelect(machine)}
              >
                {machine.machine}
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ComboMachine
