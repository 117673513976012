import React from 'react';

const ProgressBar = ({label, subLabel, value, total, isSidebarCollapsed, onClick}) => {
  const percentage = (value / total) * 100;

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-between text-xs'
        style={{
          flexDirection: isSidebarCollapsed ? 'column' : 'row',
          alignItems: isSidebarCollapsed ? 'center' : 'flex-start',
        }}
      >
        {
          !isSidebarCollapsed &&
          <>
            <span>{label}</span>
          </>
        }
        <span>{value}/{total}</span>

      </div>
      <div className='w-full h-5 rounded-md bg-neutral-300'>
        <div className='h-full rounded-md'
          style={{
            width: `${percentage}%`,
            backgroundColor: percentage > 75 ? '#2DA66D' : percentage > 50 ? '#ff9800' : '#ef2a2a'
          }}
          onClick={onClick}
        >
          {
            isSidebarCollapsed &&
            <span style={{color: '#131313', fontSize: '12px', padding: '2px'}}>{subLabel}</span>
          }
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
