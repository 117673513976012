import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {datadogRum} from "@datadog/browser-rum";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

datadogRum.init({
  applicationId: '17ebee74-9675-4962-841d-48cebf471ce7',
  clientToken: 'pubbc4b5cc18c7ad6e68680dcda2a1d7fc9',
  site: 'datadoghq.com',
  service: 'fe-caja',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: ["https://caja.wargos.cloud", "https://api4.wargos.cloud",  "https://api5.wargos.cloud"]
});

datadogRum.startSessionReplayRecording();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry:false
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();