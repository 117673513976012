import React, {useContext} from 'react'
import styles from './online-handpays.module.css';
import {HiOutlineSlotMachine} from '../../../assets/icons/slot-machine'
import {cn} from '../../../utils/general';
import axios from 'axios';
import {Apis} from '../../../utils/apis';
import {SiteContext} from '../../../context/SiteContext';
import {IoFileTrayOutline} from 'react-icons/io5';
import {MdFiberManualRecord} from 'react-icons/md';
import {LuTrash2} from 'react-icons/lu';
import {format, parseISO} from 'date-fns';
import useConfirmDialog from '../../../hooks/useConfirmDialog';

const OnlineHandPays = ({selectedMachine, setSelectedMachine, setAmountHandPay, data}) => {

  const {config} = useContext(SiteContext);

  const handleMachine = (handpay) => {
    if (selectedMachine && selectedMachine.confId === handpay.confId) {
      setSelectedMachine({confId: null, machineName: ''});
      setAmountHandPay('')
    } else {
      setSelectedMachine({id: handpay.id, confId: handpay.confId, machineName: handpay.machineName});
      setAmountHandPay(handpay?.amount)
    }
  };

  const dropElement = (id) => axios.put(Apis.TGM + '/handpays/disable/' + id);

  const dropModal = useConfirmDialog(
    '¿Deseas retirar esta operación?',
    'op_drop_handpay', //mutateKey
    'getCurrentHandpays', //volver a llamar para refrescar la data
    dropElement // accion para ejecutar en el mutate
  );

  return (
    <div className={styles.container}>
      <div className='relative flex flex-row items-start justify-center'>
        <span className='text-lg font-semibold text-center'>Pagos pendientes</span>
        <MdFiberManualRecord size={12} className='absolute top-0 max-[1024px]:right-2 right-5 animate-ping text-success-500' />
      </div>

      {data?.length === 0 ? 
        <div className="flex flex-col items-center gap-4 mt-4 text-center text-neutral-400">
          <span className='text-sm font-light'>No hay pagos pendientes</span>
          <IoFileTrayOutline size={36} />
        </div>
       : 
        data?.filter(handpay => handpay.amount >= config.minimumHp).map(handpay => (
          <div
            key={handpay.id}
            className={cn(styles.containerItem, selectedMachine?.id === handpay?.id ? styles.selectedMachine : '')}
            onClick={() => handleMachine(handpay)}
          >
            <div className='relative flex flex-row gap-2'>
              <div 
                className={cn(styles.containerIcon,'absolute right-0 !w-8 !h-8 rounded-lg')}
                onClick={() => dropModal.handleClick(handpay.id)}
              >
                <LuTrash2/>
              </div>
              <div className={cn(styles.containerIcon)}>
                <HiOutlineSlotMachine size={24}/>
              </div>
              <div className='flex flex-col'>
                <span className='font-bold'>{handpay.machineName}</span>
                <span className='text-sm font-normal text-neutral-500'>{handpay.groupName || '---'}</span>
              </div>
            </div>
            <div className='flex flex-row gap-1'>
              <span>Monto:</span>
              <span className='font-bold'>{config.symbol} {handpay.amount}</span>
            </div>
            <div className='flex flex-row gap-1'>
              <span>Fecha:</span>
              <span className='font-bold'>{handpay.fechaEmision ? format(parseISO(handpay.fechaEmision), "dd/MM hh:mm a") : '--'}</span>
              
            </div>
          </div>
        ))
      }
      <dropModal.ConfirmationModal />
    </div>
  )
}

export default OnlineHandPays
