import React, {useContext, useMemo, useRef, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {BlockTitle, ComboMachine, EmptyState, ReactTable} from '../../components/blocks'
import {SiteContext} from '../../context/SiteContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {toast} from '../../hooks/useToast';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {format, parseISO} from 'date-fns';
import {LuBanknote, LuListOrdered} from 'react-icons/lu';
import {formatNumber} from '../../utils/general';
import {queryClient} from '../..';
import OnlineBill from "../../components/blocks/online-bill";

const Bill = () => {

  const {salaId, fondo, config, printer} = useContext(SiteContext);
  const [amount, setAmount] = useState('');
  const [selectedMachine, setSelectedMachine] = useState(null);
  const inputRef = useRef(null);

  const billMutation = useMutation({
    mutationKey: ['op_bill'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/operation-billetero', data),
    onSuccess: () => {
      setAmount('');
      setSelectedMachine(null);
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      queryClient.invalidateQueries({queryKey: ['getBills']});
      inputRef.current.focus();
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (amount && selectedMachine) {
      billMutation.mutate({
        amount: amount,
        salaId: salaId,
        confId: selectedMachine.confId,
        fondoId: fondo?.id,
        printerId: printer?.printerId
      });
    }
  };

  const {data} = useQuery({
    queryKey: ['getBills', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-billetero/report/by-fondo?fondoId=' + fondo?.id)
      .then((res) => res.data),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/operation-billetero/' + id);

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_bill', //mutateKey
    'getBills', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm'
                    onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm'>Imprimir</Button>
          </div>
        ),
      }
    ]
    , [deleteModal, config]
  );

  const {data: billOnline} = useQuery({
    queryKey: ['getCurrentBill'],
    queryFn: async () => {
      const config = {
        headers: {
          'X-Sala-ID': salaId
        }
      };
      const response = await axios.get(Apis.CAJA + '/report-bill/online', config);
      return response.data;
    },
    gcTime: 1,
    refetchInterval: 5000
  });

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <BlockTitle title='BILLETERO' className='m-0'/>
      <div className='flex flex-row items-start flex-1 gap-4'>
        <div className='flex flex-col flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
          <form className='flex flex-col self-stretch gap-6 pb-4 bg-neutral-800 rounded-2xl' onSubmit={handleSubmit}>
            <div className='flex flex-row items-center gap-4'>
              <FormControl className='flex-1'>
                <label>Máquina</label>
                <ComboMachine selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine}/>
              </FormControl>
              <FormControl className='flex-1'>
                <label>Monto</label>
                <Input
                  placeholder="00.0"
                  type='number'
                  className='text-2xl font-semibold'
                  autoComplete="off"
                  value={amount}
                  ref={inputRef}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormControl>
            </div>
            <Button size='lg' type='submit'>Guardar</Button>
          </form>
          <div className='flex flex-col self-stretch flex-1 gap-6 bg-neutral-800 rounded-2xl'>
            <div className='flex flex-row items-center gap-4'>
              <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
                <div className='flex flex-col flex-1'>
                  <span className='text-lg font-bold'>{data?.quantity ? formatNumber(data.quantity, null) : '--'}</span>
                  <span className='text-xs font-light'>Nro de Operaciones</span>
                </div>
                <div className='p-3 bg-neutral-900 rounded-xl'>
                  <LuListOrdered size={20}/>
                </div>
              </div>
              <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
                <div className='flex flex-col flex-1'>
                <span
                  className='text-lg font-bold'>{data?.totalAmount ? formatNumber(data.totalAmount, config.symbol) : '--'}</span>
                  <span className='text-xs font-light'>Total</span>
                </div>
                <div className='p-3 bg-neutral-900 rounded-xl'>
                  <LuBanknote size={20}/>
                </div>
              </div>
            </div>
            {data?.data && data?.data.length > 0 ?
              <ReactTable columns={columns} data={data?.data}/>
              :
              <EmptyState/>
            }
          </div>
        </div>
        <div className='flex flex-col max-h-[700px] overflow-y-auto overflow-x-hidden'>
          {
            billOnline &&
            <OnlineBill
              data={billOnline.filter(item => item.expected > 0)}
              setAmountBill={setAmount}
              selectedMachine={selectedMachine}
              setSelectedMachine={setSelectedMachine}
            />
          }
        </div>
      </div>

      <deleteModal.ConfirmationModal/>
    </div>
  )
}

export default Bill
