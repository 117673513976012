import React from 'react';
import BlockLoading from '../block-loading';
import ErrorState from '../error-state';

const AsyncContent = ({data, isFetched, isLoading, isError, error, children}) => {
  
  if (isLoading && !isFetched) {
    return (
      <div className="flex items-center justify-center h-full">
        <BlockLoading/>
      </div>
    );
  }

  if (isError) {
    const errorCode = error?.response?.status;
    const errorMessage = error?.response?.data?.error || 'Hubo un error inesperado';

    return (
      <div className="flex items-center justify-center h-full">
        <ErrorState title={`Error ${errorCode}: ${errorMessage}`}/>
      </div>
    );
  }

  return (isFetched && data) && children;
};

export default AsyncContent;