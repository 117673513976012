import {useContext} from "react";
import {SiteContext} from "../context/SiteContext";
import {Apis} from "../utils/apis";

const endpoints = {
  saleTicket: {
    cloud: `${Apis.CAJA}/operation-ticket-venta`,
    local: 'https://servidor.wargos.cloud/xticket/ticket-caja/venta',
  },
  redeemTicket: {
    cloud: `${Apis.CAJA}/operation-ticket`,
    local: 'https://servidor.wargos.cloud/xticket/ticket-caja/redeem'
  },
  hisSaleTicket: {
    cloud: `${Apis.CAJA}/operation-ticket-venta/report/by-fondo`,
    local: 'https://servidor.wargos.cloud/xticket/ticket-report/by-fondo'
  },
  hisRedeemTicket: {
    cloud: `${Apis.CAJA}/operation-ticket/report/by-fondo`,
    local: `${Apis.CAJA}/operation-ticket/report/by-fondo`
  }
};

const useEndpoint = (type) => {
  const { modeLocal } = useContext(SiteContext);

  return modeLocal ? endpoints[type].local : endpoints[type].cloud;
};

export default useEndpoint;
