import React, {useContext, useMemo} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import {format, parseISO} from 'date-fns';
import {Button} from '../../components/atoms';
import {AsyncContent, EmptyState, ReactTable} from '../../components/blocks';
import {formatNumber} from '../../utils/general';
import {toast} from '../../hooks/useToast';
import CardSummary from "./hisUtils";

const HisExpenses = () => {

  const {fondo, config, printer} = useContext(SiteContext);

  const {data, isFetched, isLoading,isError, error} = useQuery({
    queryKey: ['getOthers', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/operation-other/report/by-fondo?fondoId=' + fondo?.id)
      .then((res) => res.data)
      .catch((err) => {
        console.error('Error fetching expenses history', err);
        throw err;
      }),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/operation-other/' + id);

  const printMutation = useMutation({
    mutationKey: ['print_expenses'],
    mutationFn: (id) => axios.get(Apis.CAJA + '/operation-other/' + id + '/print?printerId=' + printer?.printerId),
    onSuccess: () => {
      toast({
        title: "Impresion realizada!",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_others', //mutateKey
    'getOthers', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Categoria',
        accessorKey: 'categoryName'
      },
      {
        header: 'Descripción',
        id: 'description',
        accessorFn: row => `${row.description !== '' ? row.description : '--'}`,
        meta: {
          columnClassName: 'text-xs max-w-[200px]',
        }
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            <Button variant='outline' size='sm'
              onClick={() => deleteModal.handleClick(info.row.original.id)}>Eliminar</Button>
            <Button size='sm' onClick={() => printMutation.mutate(info.row.original.id)}>Imprimir</Button>
          </div>
        ),
      }
    ],
    [deleteModal, config, printMutation]
  );

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <AsyncContent
        data={data}
        isFetched={isFetched}
        isLoading={isLoading}
        isError={isError}
        error={error}
      >
        <div className='flex flex-row items-center gap-4'>
          <CardSummary
            text='Nro de Operaciones'
            value={data?.quantity}
          />
          <CardSummary
            text='Total'
            value={formatNumber(data?.totalAmount, config.symbol)}
          />
        </div>
        {data && data.data.length > 0 ?
          <ReactTable columns={columns} data={data.data} />
          :
          <div className="relative w-full h-full overflow-hidden">
            <EmptyState />
          </div>
        }
      </AsyncContent>
      <deleteModal.ConfirmationModal />
    </div>
  )
}

export default HisExpenses
