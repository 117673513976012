import React, {useContext, useState} from 'react'
import {FormControl} from '../../components/atoms'
import styles from './operations.module.css';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {SiteContext} from '../../context/SiteContext';
import {toast} from '../../hooks/useToast';
import useEndpoint from "../../hooks/useEndpoint";

const SaleTicketMostPrinted = ({onFocusInput}) => {

  const {fondo, config, printer, modeLocal} = useContext(SiteContext);
  const saleEndpoint = useEndpoint('saleTicket');
  const [amount, setAmount] = useState('');

  const saleTicketMutation = useMutation({
    mutationKey: ['op_ticket_sale_most_recent'],
    mutationFn: (data) => axios.post(saleEndpoint, data),
    onSuccess: () => {
      setAmount('');

      toast({
        title: "Operación realizada",
        variant: 'success',
        duration: 2000
      })
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleAmountSelect = (selectedAmount) => {
    setAmount(selectedAmount);

    if (selectedAmount > 0) {
      const valueInCents = Math.round(parseFloat(selectedAmount) * 100);
      let finalValue = selectedAmount;
      if (modeLocal) {
        finalValue = valueInCents
      }

      saleTicketMutation.mutate({
        amount: finalValue,
        amountCents: valueInCents,
        validationType: 0,
        fondoId: fondo.id,
        computerId: printer?.computerId
      });
    }
  };

  return (
    <>
      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <form className='flex flex-col gap-6'>
          <FormControl>
            <div className='flex flex-row items-center self-stretch flex-1 gap-4'>
              {config.amountsTi && config.amountsTi.split(',').map((item, index) => (
                <div
                  key={index}
                  className={amount === item ? styles.selectedButton : styles.buttonGroup}
                  onClick={() => handleAmountSelect(item)}
                >
                  {config.symbol} {item}
                </div>
              ))}
            </div>
          </FormControl>
        </form>
      </div>
    </>
  )
}

export default SaleTicketMostPrinted
