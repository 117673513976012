import React, {useContext, useMemo, useState} from 'react'
import {SiteContext} from '../../context/SiteContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {format, parseISO, subDays} from 'date-fns';
import {formatNumber, getStatusText} from '../../utils/general';
import {Button, FormControl} from '../../components/atoms';
import {BlockLoading, BlockTitle, ComboMachine, EmptyState, ReactTable} from '../../components/blocks';
import {LuCopy} from 'react-icons/lu';
import {toast} from '../../hooks/useToast';

const TicketMachine = () => {

  const {config} = useContext(SiteContext);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const dateFin = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  const dateIni = format(subDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss");

  const {data, isFetching} = useQuery({
    queryKey: ['getEmitTickets', selectedMachine?.confId],
    queryFn: async () => await axios.get(Apis.TICKET + '/ticket?ini=' + dateIni + '&fin=' + dateFin + '&confId=' + selectedMachine?.confId + '&type=false')
      .then((res) => res.data),
    enabled: !!selectedMachine?.confId
  })

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copiado",
        variant: 'info',
        duration: 1500
      })
    });
  }

  const columns = useMemo(
    () => [
      {
        header: 'Emitido en',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Estado',
        accessorKey: 'status',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ({row}) => (getStatusText(row.original.status, row.original.validationType)),
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount * 0.01, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Número ticket',
        accessorKey: 'validNumber',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row items-center justify-end gap-2'>
            <span>{info.row.original.validNumber}</span>
            <Button
              size='sm'
              variant='outline'
              className='px-2'
              onClick={() => copyClipboard(info.row.original.validNumber)}
            >
              <LuCopy size={14}/>
            </Button>
          </div>
        ),
      },
      {
        header: 'Creado',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Canjeado en',
        accessorKey: 'machineRedeem',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Canjeado',
        id: 'redeemAt',
        accessorFn: row => `${row.redeemAt ? format(parseISO(row.redeemAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      }
    ]
    , [config]
  );

  const limitedData = data && data.data
    ? data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 5)
    : [];

  return (
    <div className='flex flex-col self-stretch flex-1 gap-4'>
      <BlockTitle title='TICKETS EMITIDOS' className='m-0'/>
      <div className='flex flex-col self-stretch gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <FormControl>
          <label>Máquina:</label>
          <ComboMachine selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine}/>
        </FormControl>
        {isFetching ?
          <BlockLoading/>
          : (
            limitedData.length > 0 ?
              <ReactTable columns={columns} data={limitedData}/>
              :
              <EmptyState/>
          )}
      </div>
    </div>
  )
}

export default TicketMachine
