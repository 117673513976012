import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {randomString} from "../utils/general";

const WauthContext = React.createContext({

  loginWithRedirect: null,
  logout: null,
  isAuthenticated: null,
  isLoading: false,
  currentUser: null
})

export const useWauth = () => {
  return useContext(WauthContext)
};

const WauthProvider = ({domain, clientId, redirectUri, children}) => {


  let location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleAuthentication = () => {
    const params = getUrlParams()
    const idToken = params.get("#id_token")

    localStorage.setItem('currentUser', JSON.stringify({username: 'User', token: idToken}));
    setIsAuthenticated(true)
  }

  function getUrlParams() {
    const arr = new Map();
    const pairs = location.hash.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      arr.set(key, value);
    }
    return arr;
  }

  useEffect(() => {
    if (localStorage.currentUser) {
      setIsAuthenticated(true)
      setIsLoading(false)
    } else {
      setIsAuthenticated(false)
      setIsLoading(false)
    }
  }, []);

  const loginWithRedirect = () => {
    const authorizeEndpoint = domain + '/authorize'
    const state = randomString(20)

    const params = {
      state: state,
      redirect_uri: redirectUri,
      client_id: clientId,
      scope: "profile email"
    }

    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  const logout = ({returnTo}) => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("name");
    localStorage.removeItem("salaId");
    localStorage.removeItem("sala");
    localStorage.removeItem("fondo");
    localStorage.removeItem("banks");
    localStorage.removeItem("expenses");
    const authorizeEndpoint = domain + '/auth/logout'

    const params = {redirect_uri: returnTo, client_id: clientId}
    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  return (
    <WauthContext.Provider
      value={{
        loginWithRedirect: loginWithRedirect,
        logout: logout,
        isAuthenticated: isAuthenticated,
        isLoading: isLoading,
        handleAuthentication: handleAuthentication
      }}>
      {isLoading ?
        <div>Loading...</div>
        :
        children
      }
    </WauthContext.Provider>
  );

};

export default WauthProvider;
