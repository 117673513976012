import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogOverlay, DialogTitle, FormControl, Input} from "../../atoms";
import {ComboMachine, DialogConfirm, ReactTable} from "../index";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {Apis} from "../../../utils/apis";
import {toast} from "../../../hooks/useToast";
import {formatNumber} from "../../../utils/general";
import {SiteContext} from "../../../context/SiteContext";
import {format, parseISO} from "date-fns";

const CreateTicket = ({validNumber}) => {
  const {config} = useContext(SiteContext);
  const [selectedMachine, setSelectedMachine] = useState({confId: null, machineName: ''});
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [amountCheck, setAmountCheck] = useState('')
  const [selectedTicket, setSelectedTicket] = useState(null);

  const createManualTicket = useMutation({
    mutationKey: ['create_manual_ticket'],
    mutationFn: (data) => axios.post(Apis.TICKET + '/ticket-by-caja/create', data),
    onSuccess: () => {
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      handleClear()
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      handleClear()
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleClear = () => {
    setTableStacker([]);
    setOpen(false);
    setSelectedMachine({confId: null, machineName: ''});
    setSelectedTicket(null);
  };

  const [tableStacker, setTableStacker] = useState([]);

  const stackerTransactionMutation = useMutation({
    mutationKey: ['stacker_transaction'],
    mutationFn: (data) => axios.get(Apis.CAJA + `/operation-ticket/by-conf?confId=${data.confId}`, data),
    onSuccess: (response) => {
      setTableStacker(response.data);
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      });
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  useEffect(() => {
    setTableStacker([]);
  }, [validNumber]);

  const columns = useMemo(
    () => [
      {
        header: 'N° Ticket',
        accessorKey: 'validNumber',
      },
      {
        header: 'Creación',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm:ss a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Monto',
        id: 'amount',
        accessorFn: row => `${row.amount ? formatNumber(row.amount, config.symbol) : '--'}`,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: '',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            {!info.row.original.created ?
              <Button variant='outline' size='sm'
                onClick={() => {
                  setSelectedTicket(info.row.original);
                  setConfirmOpen(true);
                }}>
                Vincular
              </Button>
              :
              <span className='text-xs'>Ticket ya existe</span>
            }
          </div>
        ),
      }
    ], [config]
  );

  useEffect(() => {
    if (selectedMachine.confId) {
      stackerTransactionMutation.mutate({
        confId: selectedMachine.confId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  const handleConfirm = () => {
    if (selectedTicket) {
      const amountToCompare = selectedTicket.amount;

      if (parseFloat(amountCheck) === amountToCompare) {
        createManualTicket.mutate({
          ...selectedTicket,
          vn: validNumber.substring(2),
          validationType: 0,
          amount: selectedTicket.amount * 100,
          validationSystemId: validNumber.substring(0, 2)
        });
        setConfirmOpen(false);
        setOpen(false);
        toast({
          title: "Vinculado correctamente!",
          variant: 'success',
          duration: 2000
        });
      } else {
        console.log("Los montos no coinciden.");
        toast({
          title: "El monto ingresado no coincide.",
          variant: 'danger',
          duration: 2000
        });
      }
    }
  };

  return (
    <>
      <div>
        <Button type="button" variant='outline' size='sm' className='px-6' onClick={() => setOpen(true)}>
          Registrar ticket
        </Button>
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay />
        <DialogContent className='min-w-[700px]'>
          <DialogHeader>
            <DialogTitle>Registrar ticket</DialogTitle>
            <DialogDescription>
              Por favor, seleccione una máquina:
            </DialogDescription>
          </DialogHeader>
          <div className='flex flex-col gap-4 mt-4'>
            <FormControl>
              <label>Máquina</label>
              <ComboMachine selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine} />
            </FormControl>
            {selectedMachine.confId &&
              <FormControl>
                {tableStacker.length > 0 &&
                  <ReactTable columns={columns} data={tableStacker} filter={false} />
                }
              </FormControl>
            }
          </div>
        </DialogContent>
      </Dialog>
      <DialogConfirm
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
        disabled={createManualTicket.isPending}
        body={
          <div className='flex flex-col gap-2 my-4'>
            <span className='mb-4 text-neutral-500'>Ingrese el monto del ticket para confirmar</span>
            <Input
              placeholder="S/ 00.0"
              className='flex-1 text-left'
              type="number"
              autoComplete="off"
              autoFocus
              value={amountCheck}
              onChange={(e) => setAmountCheck(e.target.value)}
            />
          </div>
        }
      />
    </>
  );
}
  ;

export default CreateTicket;
