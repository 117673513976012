import {LuBanknote} from "react-icons/lu";
import React from "react";

const CardSummary = ({text, value}) => {
  return (
    <div className='flex flex-row flex-1 gap-2 px-4 py-3 border rounded-2xl border-neutral-700'>
      <div className='flex flex-col flex-1'>
        <span className='text-lg font-bold'>{value}</span>
        <span className='text-xs font-light'>{text} </span>
      </div>
      <div className='p-3 bg-neutral-900 rounded-xl'>
        <LuBanknote size={20}/>
      </div>
    </div>
  )
}

export default CardSummary
