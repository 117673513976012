import React, {useContext, useRef, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {SiteContext} from '../../context/SiteContext';
import styles from './refills.module.css';
import {ComboMachine, SidebarHistory} from '../../components/blocks';
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../utils/apis';
import {toast} from '../../hooks/useToast';
import {queryClient} from '../..';
import HisRefill from "../historial/hisRefill";

const amounts = [50, 100, 200];

const Refills = () => {

  const {salaId, fondo,printer} = useContext(SiteContext);
  const [amount, setAmount] = useState(100);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const inputRef = useRef(null);

  const getButtonStyle = (buttonAmount) => {
    return amount === buttonAmount ? styles.selectedButton : styles.buttonGroup;
  };

  const refillMutation = useMutation({
    mutationKey: ['op_refill'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/refill', data),
    onSuccess: () => {
      setAmount('');
      setSelectedMachine(null);
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      queryClient.invalidateQueries({queryKey: ['getRefills']});
      inputRef.current.focus();
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (amount && selectedMachine) {
      refillMutation.mutate({
        amount: amount,
        salaId: salaId,
        confId: selectedMachine.confId,
        printerId: printer?.printerId,
        fondoId: fondo?.id,
      });
    }
  };

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <div className='flex flex-col self-stretch gap-6 mt-4 bg-neutral-800 rounded-2xl'>
        <FormControl>
          <label>Montos mas usados</label>
          <div className='flex flex-row items-center self-stretch flex-1 gap-4'>
            {amounts.map((item, index) => (
              <div key={index} className={getButtonStyle(item)} onClick={() => setAmount(item)}>
                {item}
              </div>
            ))}
          </div>
        </FormControl>
        <div className='flex flex-row items-center gap-4'>
          <FormControl className='flex-1'>
            <label>Monto</label>
            <Input
              placeholder="00.0"
              type='number'
              className='text-2xl font-semibold'
              autoComplete="off"
              value={amount}
              ref={inputRef}
              onChange={(e) => setAmount(e.target.value)}
            />
          </FormControl>
          <FormControl className='flex-1'>
            <label>Máquina</label>
            <ComboMachine selectedMachine={selectedMachine} setSelectedMachine={setSelectedMachine} />
          </FormControl>
        </div>
        <Button size='lg' type='submit' onClick={handleSubmit}>Guardar</Button>
        <SidebarHistory
          title='Historial - Rellenos'
        >
          <HisRefill />
        </SidebarHistory>
      </div>
    </div>
  )
}

export default Refills
