import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Table, TableBody, TableCell, TableHeader, TableRow} from '../../atoms';
import {RxCaretDown, RxCaretSort, RxCaretUp} from 'react-icons/rx';
import {LuChevronLeft, LuChevronRight, LuChevronsLeft, LuChevronsRight} from 'react-icons/lu';

const ReactTable = ({data, columns, sortArray = [], hideInitialColumns, filter = true, initialPageSize = 10}) => {

  const [sorting, setSorting] = React.useState(sortArray);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState(
    hideInitialColumns
      ? hideInitialColumns.reduce((acc, column) => {
        acc[column.id] = false;
        return acc;
      }, {})
      : {}
  );
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [grouping, setGrouping] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      grouping
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getGroupedRowModel: getGroupedRowModel(),
    onGroupingChange: setGrouping,
    initialState: {
      pagination: {
        pageSize: initialPageSize,
      },
    },

  });

  const renderSortIndicator = (sortDirection) => {
    if (sortDirection === "asc") {
      return <RxCaretDown style={{marginLeft: "4px", display: "initial"}} />;
    } else if (sortDirection === "desc") {
      return <RxCaretUp style={{marginLeft: "4px", display: "initial"}} />;
    } else {
      return <RxCaretSort style={{marginLeft: "4px", display: "initial"}} />;
    }
  };

  return (
    <div className='flex flex-col gap-2'>
      {
        filter &&
        <Input
          type="text"
          className='h-10 text-base placeholder:text-base'
          placeholder="Filtrar..."
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      }
      <Table>
        <TableHeader className='text-white/30'>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow className key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    {...{
                      className: (header.column.columnDef.meta)?.headerClassName,
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort() ?
                      <>
                        {renderSortIndicator(header.column.getIsSorted())}
                      </>
                      : null
                    }
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map(row => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell
                      key={cell.id}
                      align={(cell.column.columnDef.meta)?.align}
                      {...{
                        className: (cell.column.columnDef.meta)?.columnClassName,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <div className="flex flex-row items-center self-stretch gap-2">
        <div className="flex items-center flex-1 gap-1">
          <span>Página</span>
          <strong>
            {table.getState().pagination.pageIndex + 1} de{' '}
            {table.getPageCount()}
          </strong>
        </div>
        <div className='flex flex-row items-center gap-2'>
          <Button
            variant='outline'
            className='w-10 h-10 p-0'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <LuChevronsLeft size={20} />
          </Button>
          <Button
            variant='outline'
            className='w-10 h-10 p-0'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <LuChevronLeft size={20} />
          </Button>
          <Button
            variant='outline'
            className='w-10 h-10 p-0'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <LuChevronRight size={20} />
          </Button>
          <Button
            variant='outline'
            className='w-10 h-10 p-0'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <LuChevronsRight size={20} />
          </Button>
          <Select
            value={table.getState().pagination.pageSize}
            onValueChange={e => {
              table.setPageSize(Number(e));
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filas" />
            </SelectTrigger>
            <SelectContent>
              {[10, 20, 30, 40, 50].map(pageSize => (
                <SelectItem key={pageSize} value={pageSize}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default ReactTable;
