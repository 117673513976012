const baseIp = 'https://api5.wargos.cloud'
const api4 = 'https://api4.wargos.cloud'
const api5 = 'https://api5.wargos.cloud'

export const Apis = Object.freeze({
  BASE: baseIp,
  OAUTH: 'https://accounts.wargos.cloud',
  USER: `${baseIp}/xuser`,
  FILE: `${baseIp}/xfile`,
  PRINT: `${api5}/xprint`,
  SALAS: `${baseIp}/go-salas`,
  TGM: `${api4}/xtgmconex`,
  LUDOP: `${baseIp}/xblacklist`,
  BILLING: `${api4}/xbilling`,
  SEARCH: `${api5}/xsearch`,
  CAJA: `${api5}/xcaja`,
  TICKET: `${api5}/xticket`,
  PC: `${api4}/xpointcore`,
  PERSON: `${api5}/xperson`,
});
