import clsx from "clsx";
import {twMerge} from "tailwind-merge";
import {format, formatDistanceToNow, formatISO, parseISO, subDays, toDate} from 'date-fns';
import {es} from "date-fns/locale";
import {LuCheck, LuCheckCheck, LuInfo, LuLoader, LuX} from "react-icons/lu";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getDate = () => {
  const currentDate = toDate(new Date());
  const formattedDate = format(currentDate, 'MMddyyyy');
  return Number(formattedDate);
};

export const getStatusText = (status, validationType) => {
  let statusText = '';

  if (validationType === 34) {
    statusText = 'BLOQUEADO';
  } else if (validationType === 16) {
    statusText = 'NO CANJEABLE';
  } else {
    switch (status) {
      case 0:
        statusText = 'PENDIENTE';
        break;
      case 1:
        statusText = 'CANJEADO';
        break;
      case 2:
        statusText = 'EXPIRADO';
        break;
      case 4:
        statusText = 'IMPRESION PENDIENTE';
        break;
      case 5:
        statusText = 'IMPRESION EN PROGRESO';
        break;
      case 6:
        statusText = 'IMPRESION FALLO';
        break;
      case 7:
        statusText = 'IMPRESION BLOQUEADO';
        break;
      default:
        statusText = '--';
        break;
    }
  }
  return statusText;
};

export const formatNumber = (number, symbol, digitos = 2) => {
  let parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    parsedNumber = 0;
  }

  if (symbol == null) {
    digitos = 0;
  }

  const formattedNumber = parsedNumber.toLocaleString('en-US', {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos
  });

  if (symbol == null) {
    return formattedNumber;
  }

  return `${symbol} ${formattedNumber}`;
};


export const getCasinoDay = () => {
  let currentDate = new Date();
  const currentHour = currentDate.getHours();
  if (currentHour < 8) {
    currentDate = subDays(currentDate, 1);
  }
  return formatISO(currentDate);
};

export const formatShortDistanceToNow = (date) => {
  const fullText = formatDistanceToNow(parseISO(date), {locale: es, addSuffix: true});

  return fullText
    .replace('alrededor de ', '')
    .replace(' minutos', ' min')
    .replace(' minuto', ' min')
    .replace(' horas', ' hrs')
    .replace(' hora', ' hrs')
    .replace(' días', 'd')
    .replace(' día', 'd')
    .replace(' meses', 'M')
    .replace(' mes', 'M')
    .replace(' años', 'A')
    .replace(' año', 'A');
};

export const getStatusIcon = (stateCode) => {
  switch (stateCode) {
    case 0://HABILITADO
      return <LuCheck size={18} className='text-green-600' />;
    case 1: //CANJEADO
      return <LuCheckCheck size={18} className='text-green-600' />;
    case 2: //EXPIRADO
      return <LuInfo size={18} className='text-gray-400' />;
    case 4:
      return <LuLoader className='animate-spin' size={18} />;
    case 5:
      return <LuLoader className='animate-spin' size={18} />;
    case 6:
      return <LuX size={18} className='text-red-600' />;
    case 7:
      return <LuX size={18} className='text-red-600' />;
    default:
      return <LuInfo size={18} className='text-gray-400' />;
  }
};
